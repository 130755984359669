import { useMemo } from 'react'

import { getWhiteLabelPublisherId } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import PublishersFieldset from '../../SelectPublishersV2'

// import { Container } from './styles';

const SelectPublisher: React.FC = () => {
  const query = useQueryParams()
  const publisherIdParam = useMemo(() => query.get('rmid') || null, [query])
  const { t } = useAPPTranslation()
  const { isWhiteLabel } = useAppInfo()

  const publisherId = useMemo(
    () => (isWhiteLabel ? getWhiteLabelPublisherId() : publisherIdParam),
    [isWhiteLabel, publisherIdParam]
  )

  return (
    <>
      {!isWhiteLabel && !publisherId && (
        <PublishersFieldset
          description={t('rm:campaign.create.publisherdescription')}
        />
      )}
    </>
  )
}

export default SelectPublisher
