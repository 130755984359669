import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { Scope } from '@unform/core'
import { t } from 'i18next'

import Panel from '~/components/Panel'
import { FormGroup, Input, Label } from '~/components/unform'
import { getWhiteLabelPublisherId } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import { getPublisherConfiguration } from '~/modules/retailMedia/store/industry/publisherConfiguration/actions'
import { useAppSelector } from '~/store/hooks'

import AlertBrands from '../../AlertBrands'

const CampaignSettings = () => {
  const publisherIdParam = useQueryParams().get('rmid')

  const { isWhiteLabel } = useAppInfo()

  const publisherId = useMemo(
    () => (isWhiteLabel ? getWhiteLabelPublisherId() : publisherIdParam),
    [isWhiteLabel, publisherIdParam]
  )

  const dispatch = useDispatch()

  const {
    industry: {
      publisherConfiguration: {
        list: { data, loading, error }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  useEffect(() => {
    if (publisherId) {
      dispatch(getPublisherConfiguration(publisherId))
    }
  }, [dispatch, publisherId])
  return (
    <Scope path="settings">
      <Panel
        title="Links de destino"
        description={t('rm:adsDestination.helperText')}
        isCollapsible
      >
        <FormGroup>
          <header className="mb-2">
            <AlertBrands publisherId={publisherId} />
          </header>

          {/* REFACToR BEFORE RELEASE - @brenonovelli */}
          {!data?.configuration?.brands?.length && (
            <Input name="destination_url" id="destination_url" />
          )}

          {!!data?.configuration?.brands?.length && (
            <Scope path="destination_urls">
              {data?.configuration?.brands.map(item => (
                <FormGroup key={item.code} className="mb-2">
                  <Label
                    text={item.name}
                    htmlFor={`destination_url[${item.code}]`}
                  />
                  <Input
                    name={item.code}
                    id={`destination_url[${item.code}]`}
                  />
                </FormGroup>
              ))}
            </Scope>
          )}
        </FormGroup>
      </Panel>
    </Scope>
  )
}

export default CampaignSettings
