import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { FiHelpCircle } from 'react-icons/fi'
import { NumberFormatValues } from 'react-number-format'
import { useDispatch } from 'react-redux'

import { t } from 'i18next'

import Alert from '~/components/Alert'
import AlertInfo from '~/components/AlertInfo'
import Panel from '~/components/Panel'
import Tooltip from '~/components/Tooltip'
import { Badge } from '~/components/UI/Badge'
import { FormGroup, InputMoney, Label } from '~/components/unform'
import { formatMoney, formatMoneyV2, unformatMoney } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import { getMinCostsCampaign } from '~/modules/retailMedia/store/grocery/configuration/action'
import { useAppSelector } from '~/store/hooks'

import AudienceCard from '../../Audiences/Card'
import BalanceByPublisher from '../../BalanceByPublisher'
import { useCampaignForm } from '../hooks/useCampaignForm'

/**
 * Format by type
 */
type FormatByType = {
  [key in CampaignAdType]: (data: CreateCampaignFormData) => Record<unknown>
}

const fakeBudget = {
  daily_budget: '0',
  cpc: '0',
  cpm: '0'
}

const formatProducts = (data: CreateCampaignFormData) =>
  data?.products?.map(item => ({
    id: item.id ? String(item.id) : undefined,
    sku: item.sku ? String(item.sku) : undefined,
    categories: item.categories || undefined
  })) || null

const formatByType: FormatByType = {
  product: data => ({
    start_at: data.start_date,
    type: 'on_site',
    products: formatProducts(data),
    settings: {
      ...fakeBudget
    }
  }),
  digital_signage: data => ({
    start_at: data.start_date,
    type: 'in_store',
    settings: {
      ...fakeBudget
    }
  }),
  banner: data => ({
    start_at: data.start_date,
    type: 'on_site',
    banner: {
      keywords: data.targeting?.keywords?.split(',') || null,
      categories: data.targeting?.categories?.split(',') || null,
      assets: data.banners.map(item => ({
        ad_size: item.ad_size,
        alt_text: item.alt_text,
        media_url: item.media_url
      }))
    },
    settings: {
      ...fakeBudget
    }
  }),
  sponsored_brand: data => ({
    start_at: data.start_date,
    type: 'on_site',
    settings: {
      products: formatProducts(data),
      ...fakeBudget
    },
    brand_ad: {
      keywords: data.targeting?.keywords?.split(',') || null,
      categories: data.targeting?.categories?.split(',') || null
    }
  })
}

const Budget = ({
  adType,
  publisherId,
  formData,
  formIsValid,
  handleIsValid,
  initialValueDailyBudget
}: BudgetProps) => {
  const [CPC, setCPC] = useState(0)
  const [CPM, setCPM] = useState(0)
  const [minDailyBudget, setMinDailyBudget] = useState(
    initialValueDailyBudget ? initialValueDailyBudget : 0
  )
  const [budgetValueIsValid, setBudgetValueIsValid] = useState(false)
  const [dailyBudgetIsValid, setDailyBudgetIsValid] = useState(false)

  const { selectedAudience } = useCampaignForm()

  const { currencyCode, isWhiteLabel, lengthCentsCurrency } = useAppInfo()

  const dispatch = useDispatch()

  const {
    grocery: {
      configuration: {
        minCosts: { data, error }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  const isReady = useMemo(
    () => formIsValid.isValid && (!!publisherId || !!isWhiteLabel),
    [formIsValid.isValid, publisherId, isWhiteLabel]
  )

  const payload = useMemo(() => {
    const data = {
      ad_type: adType,
      name: formData?.name,
      ...formatByType[adType](formData)
    }

    if (publisherId) {
      data.publisher_id = publisherId
    }

    return data
  }, [adType, formData, publisherId])

  const params: ListMinCostsRequest = useMemo(
    () => ({
      errorFeedbackMsg: t(
        'rm:campaign.formV2.budget.minValueCpcOrCpm.loading.error'
      ),
      body: isReady ? payload : {}
    }),
    [isReady, payload]
  )

  // const params: ListMinCostsRequest = useMemo(
  //   () => ({
  //     errorFeedbackMsg:
  //       'Houve um erro ao carregar os valores mínimos de CPC ou CPM.',
  //     body: isReady
  //       ? formatIndustryValidateCampaign({
  //           beforeBudget: true,
  //           publisher_id: publisherId,
  //           ...formData
  //         })
  //       : {}
  //   }),
  //   [isReady, publisherId, formData]
  // )

  useEffect(() => {
    if (isReady) dispatch(getMinCostsCampaign(params))
  }, [dispatch, params, isReady])

  const handleChangeCPC = useCallback(
    (inputData: NumberFormatValues) => {
      const amount = inputData.value
      const value = lengthCentsCurrency ? Number(amount) / 100 : Number(amount)

      setCPC(value)

      setBudgetValueIsValid(value >= data?.min_cpc)
    },
    [data?.min_cpc, lengthCentsCurrency]
  )

  useEffect(() => {
    if (error) {
      handleIsValid(true)
      return
    }

    handleIsValid(budgetValueIsValid && dailyBudgetIsValid)
  }, [budgetValueIsValid, dailyBudgetIsValid, error, handleIsValid])

  const handleChangeCPM = useCallback(
    (inputData: NumberFormatValues) => {
      const amount = inputData.value
      const value = lengthCentsCurrency ? Number(amount) / 100 : Number(amount)
      setCPM(value)

      setBudgetValueIsValid(value >= data?.min_cpm)
    },
    [data?.min_cpm, lengthCentsCurrency]
  )

  const handleChangeDailyBudget = useCallback(
    (inputData?: NumberFormatValues) => {
      const amount = inputData.value

      const value = lengthCentsCurrency ? Number(amount) / 100 : Number(amount)

      setMinDailyBudget(value)

      setDailyBudgetIsValid(value >= data?.min_daily_budget)
    },
    [data, lengthCentsCurrency]
  )

  /**
   * Formata o valor inicial do input de orçamento diario e envia
   * para a função de handle para a validação
   */
  useEffect(() => {
    if (initialValueDailyBudget) {
      const valueDailyBudget = unformatMoney(initialValueDailyBudget)
      const value = Number(valueDailyBudget) * 100
      const initialValue = {
        formattedValue: initialValueDailyBudget,
        value: `${value}`,
        floatValue: value
      }
      handleChangeDailyBudget(initialValue)
    }
  }, [handleChangeDailyBudget, initialValueDailyBudget, data])

  const valueBudgetIsValid = useMemo(() => {
    return budgetValueIsValid && dailyBudgetIsValid
  }, [budgetValueIsValid, dailyBudgetIsValid])

  return (
    <>
      <Panel
        title={t('rm:budget')}
        description={t('rm:budgetDescription')}
        headerSideContent={
          <BalanceByPublisher id={publisherId} idAccountType="industry" />
        }
      >
        <fieldset className="d-flex flex-column gap-2 justify-start">
          {!formIsValid.isValid && (
            <AlertInfo text={t('rm:campaign.form.alerts.fillAllFields')} />
          )}

          <FormGroup>
            <Label
              htmlFor="budget.daily"
              isRequired
              helperText={t('rm:dailyBudget.helper.text')}
            >
              {t('rm:dailyBudget')}
              {data?.min_daily_budget >= 0
                ? ` (${t('rm:minimum')}: ${formatMoney(data.min_daily_budget)})`
                : null}
            </Label>
            <InputMoney
              id="budget.daily"
              name="budget.daily"
              required
              disabled={!isReady}
              onChange={handleChangeDailyBudget}
              showBadgeIsvalid={minDailyBudget >= data?.min_daily_budget}
              showBadgeIsInvalid={minDailyBudget < data?.min_daily_budget}
            />
          </FormGroup>

          {(adType === 'product' || adType === 'sponsored_brand') && (
            <FormGroup className="border-top pt-2">
              <Label
                htmlFor="budget.cpc"
                isRequired
                helperText={t('rm:cpc.description')}
              >
                CPC
                {data?.min_cpc >= 0
                  ? ` (${t('rm:minimum')}: ${formatMoney(data.min_cpc)})`
                  : null}
              </Label>

              <InputMoney
                id="budget.cpc"
                name="budget.cpc"
                required
                disabled={!isReady}
                onChange={handleChangeCPC}
                showBadgeIsvalid={CPC >= data?.min_cpc}
                showBadgeIsInvalid={CPC < data?.min_cpc}
              />
            </FormGroup>
          )}

          {(adType === 'banner' || adType === 'sponsored_brand') && (
            <FormGroup className="border-top pt-2">
              <Label
                htmlFor="budget.cpm"
                isRequired
                helperText={t('rm:cpm.description')}
              >
                CPM
                {data?.min_cpm >= 0
                  ? ` (${t('rm:minimum')}: ${formatMoney(data.min_cpm)})`
                  : null}
              </Label>
              <InputMoney
                id="budget.cpm"
                name="budget.cpm"
                required
                disabled={!isReady}
                onChange={handleChangeCPM}
                showBadgeIsvalid={CPM >= data?.min_cpm}
                showBadgeIsInvalid={CPM < data?.min_cpm}
              />
            </FormGroup>
          )}

          {!!selectedAudience && (
            <section className="d-flex align-items-center gap-1 border-top pt-2">
              <div className="d-flex gap-1">
                <span role="strong" className="reach-title fw-semibold fs-6">
                  {t('rm:AudienceCard.text.audienceCost')}:
                </span>
                <span className="fs-6">
                  {formatMoneyV2({ value: selectedAudience.price })}
                </span>
              </div>
              <div className="d-flex align-items-center ">
                <Badge
                  size="small"
                  template={selectedAudience.price ? 'warning' : 'success'}
                  text={
                    selectedAudience.price
                      ? t('rm:AudienceCard.text.audienceCost.fixedCost')
                      : t('rm:AudienceCard.text.audienceCost.noCost')
                  }
                />
                <Tooltip
                  icon={<FiHelpCircle />}
                  text={t('rm:AudienceCard.text.audienceCostDescription')}
                />
              </div>
            </section>
          )}
        </fieldset>
      </Panel>

      {!(!!data && valueBudgetIsValid) && (
        <AlertInfo text={t('rm:campaign.formV2.valueBudgetIsValid.alert')} />
      )}
    </>
  )
}

export default Budget
